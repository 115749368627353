import { FunctionComponent } from 'react';
import dynamic from 'next/dynamic';
import classnames from 'classnames';

import { SecondStepElementKeys } from '../types';

import StyledComponent from './styles';
import { Props } from './types';

const LocationsMobileMenu = dynamic(() => import('components/modules/public/locations/LocationsMobileMenu'), { ssr: false });

const PublicLayoutNavigationMobileDropDownMenu: FunctionComponent<Props> = ({ className, firstStepElement, secondStepElement, setSecondStepElement }) => {
    return (
        <StyledComponent className={classnames('public-layout-navigation-mobile-drop-down-menu-second-step', 'second-step', className)}>
            <div
                className="second-step__header"
            >
                {firstStepElement?.page.mobileIcon && (
                    <div className="second-step__mobile second-step__mobile--active">
                        <img
                            src={firstStepElement?.page.mobileIcon}
                            alt="icon"
                            width="auto"
                            height="26px"
                        />
                    </div>
                )}
                <span className="active-title">
                    {secondStepElement?.title}
                </span>
            </div>
            <div>
                {secondStepElement && (
                    <LocationsMobileMenu
                        formProps={{
                            translations: {
                                cityTitle: '',
                                buttonLabel: 'Szukaj',
                                input: {
                                    city: {
                                        placeholder: 'Wpisz miasto',
                                    },
                                },
                            },
                            handlingByCategoryName: secondStepElement?.key as SecondStepElementKeys,
                            handlingByCategorySlug: secondStepElement?.slug,
                            handlingByCategoryId: secondStepElement?.id,
                        }}
                        initialProposals={secondStepElement.subsections}
                        onLinkClick={() => {
                            setSecondStepElement(prevState => ({ ...prevState, toClose: true }));
                        }}
                    />
                )}
            </div>
            <div
                className="step-back-button"
                onClick={() => setSecondStepElement(prevState => ({ ...prevState, toClose: true }))}
            >
                <img
                    className="arrow"
                    src="/images/menu/arrow.svg"
                    alt="icon"
                    width="100px"
                    height="auto"
                />
                <span>
                    Wróc
                </span>
            </div>
        </StyledComponent>
    );
};

export default PublicLayoutNavigationMobileDropDownMenu;
